
import Swiper from 'swiper' // 스와이퍼 재선언 시 사용
import dayjs from 'dayjs'
import _ from 'lodash'
import ButtonEl from '@/components/pub/ButtonEl.vue'
import TabCmp from '@/components/ds/common/TabCmp.vue' // 탭
import { commonfunc } from '~/mixin/common'
import CtgrLayer from '@/components/ds/dsa/ctgrLayer.vue'
import { groobeeUtil } from '@/mixin/groobeeUtil'
import { pushSearchGtmData } from '~/utils/gtm'

export default {
  name: 'HeaderCmp',
  components: {
    // GnbNav,
    ButtonEl,
    TabCmp,
    CtgrLayer
  },
  mixins: [commonfunc, groobeeUtil],
  props: {
    logo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    headerType: {
      type: String,
      default: ''
    },
    pageName: {
      type: String,
      default: ''
    },
    tabActive: {
      type: String,
      default: 'tab01'
    },
    backBtn: {
      type: Boolean,
      default: false
    },
    searchBtn: {
      type: Boolean,
      default: false
    },
    searchArea: {
      type: Boolean,
      default: false
    },
    basketBtn: {
      type: Boolean,
      default: false
    },
    qrBtn: {
      type: Boolean,
      default: false
    },
    mapBtn: {
      type: Boolean,
      default: false
    },
    closeBtn: {
      type: Boolean,
      default: false
    },
    moreBtn: {
      type: Boolean,
      default: false
    },
    enroBtn: {
      type: Boolean,
      default: false
    },
    homeBtn: {
      type: Boolean,
      default: false
    },
    setBtn: {
      type: Boolean,
      default: false
    },
    alarmBtn: {
      type: Boolean,
      default: false
    },
    stepLine: {
      type: Boolean,
      default: false
    },
    perVal: {
      type: String,
      default: ''
    },
    headerWhite: {
      type: String,
      default: ''
    },
    searchTerm: {
      type: String,
      default: ''
    },
    currentUrl: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dvyAddr: '',
      baseTime: '10월 06일 00:00',
      cartNum: 0,
      activeTab: 'tab1', // 검색 순위 팝업 내부 탭
      swiperOpt: { // GNB 스와이퍼
        slidesPerView: 'auto',
        loop: false,
        FreeMode: true
      },
      gnbList: [],
      gnbActive: 0,
      input1: '',
      bubbleShow: true,
      visibleAd: false, // 배송지 설정 팝업
      visibleRank: false, // 인기 검색어 팝업
      visibleRankTool: false, // 인기 검색어 기준 툴팁
      active: '1', // 탭 index(활성화)
      tabsDataSub: [ // 탭 data
        {
          id: '1',
          title: '1~10위',
          name: '1'
        },
        {
          id: '2',
          title: '11~20위',
          name: '2'
        }
      ],
      searchRankList: [], // 검색 순위 리스트
      rankingList: [],
      rankError: false, // 랭크 데이터 못불러올 때
      rankingSwiperOpt: { // 검색 순위 스와이퍼
        slidesPerView: 1,
        loop: true,
        direction: 'vertical',
        allowTouchMove: false,
        autoplay: {
          delay: 3000,
          autoplayDisableOnInteraction: false
        }
      },
      popRankSwiperOpt: {
        auto: false,
        loop: false,
        navigation: false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          type: 'bullets', // 버튼 디자인 bullets" / "fraction"
          renderBullet (index, className) {
            return '<button type="button" aria-label="' + (index + 1) + '번 슬라이드로 이동하기" class="' + className + '"><span class="blind">' + (index + 1) + '</span></button>'
          },
          renderFraction (currentClass, totalClass) {
            return '<span class="' + currentClass + '"></span>' + '<span>/</span>' + '<span class="' + totalClass + '"></span>'
          }
        },
        slidesPerView: 2,
        slidesPerGroup: 2
      },
      recentlySearchTerms: [],
      autoCompleteList: [],
      placeholders: [],
      placeholder: '',
      emptyLink: '/',
      searchInput: this.searchTerm, // 검색 input
      searchPopRecent: false, // 최근 검색어 팝업
      searchPopAuto: false, // 검색 자동완성 팝업
      swiperTotalWidth: 0, // 스와이퍼 총 넓이
      swipeWrapWidth: 0, // 스와이퍼 제어 대상 부모 넓이
      isDestroy: false, // 스와이퍼 작동 중지 여부

      fullCateVisible: false, // 카테고리 목록 mouseover 여부
      onFullCate: false, // 카테고리 목록 active
      activeIndex: null, // 서브카테고리 액티브
      loginNkNm: '',
      // 23.12.05 랭킹리스트 변경 추가
      currentIndex: 0,
      itemHeight: 20,
      trendInterval: null
    }
  },
  // head () {
  //   return {
  //     meta: [...this.setGroobeeMeta()],
  //     script: [...this.setGroobeeScript()],
  //     __dangerouslyDisableSanitizers: ['script'] // 그루비처리시 필요
  //   }
  // },
  computed: {
    swiper () {
      return this.$refs.reactiveSwiper.swiper
    },
    isLogined () {
      const mbMngNo = this.$store.getters['session/getMbMngNo']
      return !!mbMngNo
    },
    setLoginBtnNm () {
      // return (this.isLogined && '로그아웃') || '로그인'
      const mbMngNo = this.$store.getters['session/getMbMngNo']
      return this.isNotEmpty(mbMngNo) ? '로그아웃' : '로그인'
    },
    mainLogoUrl () {
      return this.imgUrlMaker(this.logo.pcomIconAtchFileUrl)
    }
  },
  watch: {
    searchTerm () {
      this.searchInput = this.searchTerm
    },
    placeholders () {
      if (this.placeholders.length > 0) {
        const index = Math.floor(Math.random() * this.placeholders.length)
        this.placeholder = this.placeholders[index].cmmnCdNm
        this.emptyLink = this.placeholders[index].scdUserFd
      } else {
        this.placeholder = ''
      }
    },
    currentUrl () {
      this.gnbActive = this.gnbList.findIndex((item) => {
        const rank = this.currentUrl.split('/')[2]
        if (rank !== 'rank') {
          return item.link === this.currentUrl
        } else {
          return item.link.split('/')[2] === rank
        }
      })
    },
    isLogined (value) {
      this.getCartNum()
      this.loginNkNm = this.$store.getters['session/getMbEfnm']
      /*
      await this.getBaseDdvyAddr()
      if (value && !this.$route.params.openTransfPopup) {
        console.log('watch에서 배송지설정안내', this.$route)
        this.showAdPopover()
      }
      */
    },
    $route: {
      immediate: true,
      handler () {
        this.getCartNum()
        this.loginNkNm = this.$store.getters['session/getMbEfnm']
      }
    }
  },
  created () {
    this.$nuxt.$on('getCartNum', this.getCartNum)
  },
  async mounted () {
    // 전시 코너정보 조회
    await this.loadGnbDsCnrList()
    // 검색창 플레이스홀더 조회
    await this.getPlaceholders()
    // 최근검색어
    this.initRecentlyWord()
    // 인기 검색어 정보 조회
    await this.loadTopSearchKeywords()
    // 장바구니 갯수
    await this.getCartNum()

    this.swiperWidth()
    this.swiperDestroy('gnb-wrap') // 파라미터 : 스와이퍼 제어 대상의 부모

    // 로그인이 되어있고, referer가 로그인화면이라면 5초간 팝업 노출
    if (this.isLogined && !this.$route.params.openTransfPopup) {
      this.showAdPopover()
    }
  },
  beforeDestroy () {
    clearInterval(this.trendInterval)
  },
  methods: {
    async getBaseDdvyAddr () {
      const mbMngNo = this.$store.getters['session/getMbMngNo']

      if (this.isNotEmpty(mbMngNo)) {
        const param = { mbMngNo }
        await this.$AxiosBuilder
          .data(param)
          .build()
          .post('/or/orc/selBassDvya')
          .then((res) => {
            console.log('/or/orc/selBassDvya ::: ', res)
            if (res.data.data.addrBassEaddr) {
              let dvyAddr = this.isNotEmpty(res.data.data.addrZip) ? '(' + res.data.data.addrZip + ')' : ''
              dvyAddr = dvyAddr + res.data.data.addrBassEaddr + ' ' + res.data.data.dtlEaddr
              this.dvyAddr = dvyAddr
            } else {
              this.dvyAddr = ''
            }
          }).catch((e) => {
            console.error('e', e)
          })
      } else {
        this.dvyAddr = ''
      }
      console.log('this.dvyAddr', this.dvyAddr)
    },
    showAdPopover () {
      setTimeout(() => {
        this.visibleAd = true
        setTimeout(() => {
          this.visibleAd = false
        }, 5000)
      }, 2000)
    },
    goHome () {
      if (this.$route.path === '/ds') {
        this.$router.go(this.$router.currentRoute)
      } else {
        this.$router.push({ path: '/ds' })
      }
      sessionStorage.setItem('filterCheck', [])
      sessionStorage.setItem('prodSortCheck', '')
    },
    errorLogo (e) {
      e.target.src = '/images/content/gnb_logo_kr.svg'
    },
    initRecentlyWord () {
      let recentlySearchTerms
      if (localStorage.getItem('recentlySearchTerms') !== null) {
        recentlySearchTerms = JSON.parse(localStorage.getItem('recentlySearchTerms'))
      } else {
        recentlySearchTerms = []
      }
      this.recentlySearchTerms = recentlySearchTerms
    },
    delRecentlyAll () {
      this.recentlySearchTerms = []
      localStorage.setItem('recentlySearchTerms', JSON.stringify(this.recentlySearchTerms))
    },
    delRecently (idx) {
      if (this.isNotEmpty(this.recentlySearchTerms[idx])) {
        this.recentlySearchTerms.splice(idx, 1)
      }
      localStorage.setItem('recentlySearchTerms', JSON.stringify(this.recentlySearchTerms))
    },
    async getPlaceholders () {
      // const placeholders = []

      const resData = await this.$AxiosBuilder
        .data()
        .build()
        .post('/ds/exh/selPlaceholder')
        .then((res) => {
          return res.data.data
        })
      this.placeholders = resData
    },
    setSearchTerm (keyword) {
      this.searchInput = keyword
      this.totalSearch()
    },
    debounceAutoComplete: _.debounce(function (value) { this.getAutoComplete(value) }, 150),
    getAutoComplete (value) {
      if (typeof value === 'string' && _.size(value) > 0) {
        const params = { searchTerm: value }
        this.$AxiosBuilder
          .params(params)
          .baseURL(process.env.API_SSN_BASE_URL2)
          .build()
          .get('/autoSearch')
          .then((res) => {
            if (res.data.returnCode === 1) {
              this.autoCompleteList = [
                ..._.chain(this.recentlySearchTerms)
                  .filter(({ keyword }) => keyword?.match(value))
                  .map(recent => ({
                    html: recent.keyword?.replace(
                      this.searchInput,
                      '<span class="same">$&</span>'),
                    ...recent,
                  }))
                  .value(),
                ..._.chain(res.data.resultSet.result[0].resultDocuments)
                  .map(item => ({
                    keyword: this.removeTag(item.keyword),
                    html: this.parseEmToSpan(item.keyword),
                  }))
                  .value(),
              ]
              this.searchPopup()
            }
          })
      } else {
        this.autoCompleteList = []
        this.searchPopup()
      }
    },
    parseEmToSpan (keyword) {
      let value = keyword
      value = value.replaceAll('<em>', '<span class="same">')
      value = value.replaceAll('</em>', '</span>')
      return value
    },
    removeTag (keyword) {
      let value = keyword
      value = value.replaceAll('<em>', '')
      value = value.replaceAll('</em>', '')
      return value
    },
    clearAutoComplete () {
      console.log('CLEAR')
    },
    async totalSearch () {
      if (!this.isNotEmpty(this.searchInput)) {
        // 입력된 검색어가 없다면 플레이스홀더에 명시된 주소로 이동
        // console.log('입력된 검색어가 없다면 플레이스홀더에 명시된 주소로 이동', this.emptyLink)
        // this.$router.push({
        //   path: this.emptyLink
        // })
        return false
      }
      // 검색어 trim 공백체크
      if (this.isNotEmpty(this.searchInput) && this.searchInput.trim() === '') {
        this.searchInput = ''
        return false
      }
      // 그루비 고객행동데이터 전달
      this.setGroobeeKeyword(this.searchInput)

      // 최근 검색어 localStorage처리
      this.recentlySearchTerms.forEach((recently, recentlyIdx) => {
        if (recently.keyword === this.searchInput) {
          this.recentlySearchTerms.splice(recentlyIdx, 1)
        }
      })

      const recentlyItem = {
        keyword: this.searchInput,
        date: dayjs().format('YYYY.MM.DD')
      }
      if (this.recentlySearchTerms.length >= 10) {
        this.recentlySearchTerms.pop()
      }
      this.recentlySearchTerms.unshift(recentlyItem)
      localStorage.setItem('recentlySearchTerms', JSON.stringify(this.recentlySearchTerms))

      this.searchPopRecent = false
      this.searchPopAuto = false

      if (this.searchInput.includes('리들샷') || this.searchInput === '1049275' || this.searchInput === '1049276') {
        const needleExbt = await this.selCmmnCd(['CD_00542'])
        if (this.isNotEmpty(needleExbt) && needleExbt.length > 0) {
          return this.$router.push(needleExbt[0].fstUserFd)
        }
      }

      pushSearchGtmData(this.searchInput) // 검색시 GTM DataLayer Push 실행
      sessionStorage.setItem('filterCheck', [])
      sessionStorage.setItem('prodSortCheck', '')
      this.$router.push({
        path: '/ds/dst/SCR_DST_0015',
        query: {
          searchTerm: this.searchInput
        }
      })
    },
    searchPopup (e, type) { // 검색 팝업 제어
      switch (type) {
        case 'blur':
          // 검색영역 제외한 부분 클릭 시
          if (e.target.closest('.search-wrap') === null) {
            this.searchPopRecent = false
            this.searchPopAuto = false
            // backTab 인풋 영역 focusout
          } else if (e.relatedTarget !== null && e.relatedTarget.classList.contains('logo')) {
            this.searchPopAuto = false
            this.searchPopRecent = false
          }
          break
        case 'popBlur':
          // 팝업 내 마지막 요소 focusout
          if (e.target.classList.contains('focus-last')) {
            this.searchPopAuto = false
            this.searchPopRecent = false
          }
          break
        default:
          // 최근 검색어, 자동완성 팝업 상태 제어 (예시)
          if (this.autoCompleteList.length === 0) {
            this.searchPopRecent = true
            this.searchPopAuto = false
          } else {
            this.searchPopAuto = true
            this.searchPopRecent = false
          }
          break
      }
    },
    onClickWindow (e) {
      this.searchPopup(e, 'blur')
    },
    /**
     * @description 전시 코너목록 조회
     * @params :
     *  exhCnrId - 코너 ID
     */
    async loadGnbDsCnrList () {
      const cnrList = await this.$AxiosBuilder
        .data({ exhCnrId: 'C1' })
        .build()
        .post('/ds/cnr/selCnrList')
        .then((res) => {
          const data = res.data.data.filter((item) => { return item.aplMdiaPcomYn === 'Y' })
          window.localStorage.setItem('corner-list', JSON.stringify(data))

          return data.map((item) => {
            if (item.pcomImgAtchFileUrl) {
              return {
                id: item.exhCnrId,
                name: this.unescape(item.iconRplcTextNm),
                img: item.pcomImgAtchFileUrl,
                alt: item.pcomImgOrglFileNm,
                link: item.pcScrUrl,
                subExhCnrList: item.subExhCnrList?.map((item) => {
                  return {
                    id: item.exhCnrId,
                    name: this.unescape(item.exhCnrNm),
                    linkUrl: item.pcScrUrl
                  }
                })
              }
            } else {
              return {
                id: item.exhCnrId,
                name: this.unescape(item.iconRplcTextNm),
                link: item.pcScrUrl,
                subExhCnrList: item.subExhCnrList?.map((item) => {
                  return {
                    id: item.exhCnrId,
                    name: this.unescape(item.exhCnrNm),
                    linkUrl: item.pcScrUrl
                  }
                })
              }
            }
          })
        })
      this.gnbList = cnrList
      this.$store.commit('local/setGnbMenuList', JSON.parse(JSON.stringify(cnrList)))
      this.setGnbActive()
    },
    setGnbActive () {
      const currentPath = this.$route.path
      this.gnbActive = this.gnbList.findIndex((item) => {
        return item.link === currentPath
      })
    },
    /**
     * @description 인기 검색어 목록 조회
     * @params :
     *  rank - 검색어 순위
     */
    async loadTopSearchKeywords () {
      this.baseTime = ''
      const data = await this.$AxiosBuilder
        .params({ rank: 20 })
        .baseURL(process.env.API_SSN_BASE_URL2)
        .build()
        .get('/trendSearch')
        .then((res) => {
          return res.data.resultSet.result[0]
        })
        .catch((e) => {
          console.error(e)
        })
      if (data) {
        const nowTime = dayjs().minute(0)
        this.baseTime = nowTime.format('MM월 DD일 HH:mm')
        this.searchRankList = []
        const resultDocuments = data.resultDocuments.map(item => ({
          rank: item.rank,
          name: item.keyword,
          up: (Number(item.preRank) - Number(item.rank)) > 0 ? (Number(item.preRank) - Number(item.rank)).toString() : '',
          down: Number(item.preRank) > 0 && (Number(item.preRank) - Number(item.rank)) < 0 ? (Number(item.rank) - Number(item.preRank)).toString() : '',
          new: Number(item.preRank) === 0
        }))

        this.rankingList = JSON.parse(JSON.stringify(resultDocuments))

        for (let i = 0; i < resultDocuments.length; i += 10) {
          this.searchRankList.push(resultDocuments.slice(i, i + 10))
        }
      } else {
        this.rankError = true
        console.log('trendSearch error')
      }
      if (this.rankingList.length !== 0 || !this.rankError) {
        this.startRolling()
      }
    },
    // == gnb swipe
    swiperWidth () { // 슬라이드 총 넓이
      const swiperItem = this.$refs?.reactiveSwiper?.$el?.querySelectorAll('.swiper-slide')

      if (this.isEmpty(swiperItem)) {
        return
      }

      let sum = 0
      swiperItem.forEach((item) => {
        sum += item.clientWidth
      })
      this.swiperTotalWidth = sum
    },
    swiperDestroy (swipeWrap) { // 슬라이드 제어
      this.swipeWrapWidth = document.getElementsByClassName(swipeWrap)[0].clientWidth

      if (this.swiperTotalWidth < this.swipeWrapWidth && this.isDestroy === false) {
        this.$refs.reactiveSwiper.swiper.destroy()
        this.isDestroy = true
        console.log('destroy ' + this.isDestroy)
      } else if (this.swiperTotalWidth > this.swipeWrapWidth && this.isDestroy === true) {
        this.$refs.reactiveSwiper.swiper = new Swiper(this.$refs.reactiveSwiper.$el, this.swiperOpt)
        this.isDestroy = false
        console.log('destroy ' + this.isDestroy)
      }
    },
    handlePopOver () {
      if (this.isLogined && this.visibleAd) {
        setTimeout(() => {
          this.visibleAd = false
        }, 5000)
      }
    },
    isActive (index) {
      return this.activeIndex === index
    },
    cateActiveInit (e) {
      if (e.target.closest('.full-category') !== null) {
        this.onFullCate = true
      } else {
        this.onFullCate = false
      }
      this.$refs.ctgr.test()
      this.fullCateVisible = true
    },
    cateActive (e) {
      if (e.target.closest('.full-category') !== null) {
        this.onFullCate = true
      } else {
        this.onFullCate = false
      }
      this.fullCateVisible = true
    },
    cateClose (e) {
      if (this.onFullCate === false) {
        this.fullCateVisible = false
      } else if (this.onFullCate && e.target.closest('.full-category') !== null) {
        this.fullCateVisible = false
      }
    },
    signout () {
      const mbMngNo = this.$store.getters['session/getMbMngNo']
      if (mbMngNo !== null && mbMngNo !== undefined && mbMngNo !== '') {
        // 로그아웃 처리 후 메인 페이지로 이동
        this.$AxiosBuilder
          .data(this.$store.getters['session/getMb'])
          .build()
          .post('/mb/mbb/logout')
          .then((res) => {
            console.log(res)
            this.$store.commit('session/setMember', {}) // 세션객체 초기화
            this.$router.push({ path: '/' })
          })
      } else {
        this.goSignin()
      }
    },
    goSignin () {
      this.visibleAd = false
      this.$router.push({ name: 'mb-mbb-SCR_MBB_0097A', params: { returnUrl: this.$route } })
    },
    openTransf () {
      this.visibleAd = false
      const mbMngNo = this.$store.getters['session/getMbMngNo']
      if (!mbMngNo) {
        const targetObj = Object.assign({}, this.$route)
        targetObj.params.openTransfPopup = true
        this.$router.push({ name: 'mb-mbb-SCR_MBB_0097A', params: { returnUrl: targetObj } })
      } else {
        this.$nuxt.$emit('openTransfPopup', true)
      }
    },
    goMyPage () {
      const mbMngNo = this.$store.getters['session/getMbMngNo']
      if (!mbMngNo) {
        console.log('로그인 해야됨', mbMngNo)
        this.$router.push({ name: 'mb-mbb-SCR_MBB_0097A', params: { returnUrl: '/mb/mbc/SCR_MBC_0110A' } })
      } else if (this.$route.path === '/mb/mbc/SCR_MBC_0110A') {
        console.log('같은경로임', this.$route.path, this.$router.currentRoute, this.$route)
        this.$router.go(this.$router.currentRoute)
      } else {
        console.log('마이페이지로 이동')
        this.$router.push({ path: '/mb/mbc/SCR_MBC_0110A' })
      }
    },
    goCart () {
      const mbMngNo = this.$store.getters['session/getMbMngNo']
      if (!mbMngNo) {
        console.log('로그인 해야됨', mbMngNo)
        this.$router.push({ name: 'mb-mbb-SCR_MBB_0097A', params: { returnUrl: '/or/orc/SCR_ORC_0001' } })
      } else if (this.$route.path === '/or/orc/SCR_ORC_0001') {
        console.log('같은경로임', this.$route.path, this.$router.currentRoute, this.$route)
        this.$router.go(this.$router.currentRoute)
      } else {
        console.log('장바구니로 이동')
        this.$router.push({ path: '/or/orc/SCR_ORC_0001' })
      }
    },
    async getCartNum () {
      const mbMngNo = this.$store.getters['session/getMbMngNo']
      if (this.isNotEmpty(mbMngNo)) {
        const payload = {
          mbMngNo: this.$store.getters['session/getMbMngNo']
        }
        await this.$AxiosBuilder
          .data(payload)
          .build()
          .post('/or/api/selSpbkCont')
          .then((res) => {
            if (res.data !== null && res.data.success) {
              this.cartNum = res.data.data?.spbkCont
            }
          }).catch((e) => {
            console.error('e', e)
          })
      } else {
        this.cartNum = 0
      }
    },
    goOrderTransp () {
      const mbMngNo = this.$store.getters['session/getMbMngNo']
      if (!mbMngNo) {
        this.$router.push({ name: 'mb-mbb-SCR_MBB_0097A', params: { returnUrl: '/or/ORB/SCR_ORB_0001' } })
      } else if (this.$route.path === '/or/ORB/SCR_ORB_0001') {
        this.$router.go(this.$router.currentRoute)
      } else {
        this.$router.push({ path: '/or/ORB/SCR_ORB_0001' })
      }
    },
    goMembership () {
      if (this.$route.path === '/ms/msb/SCR_MSB_0001') {
        this.$router.go(this.$router.currentRoute)
      } else {
        this.$router.push({ path: '/ms/msb/SCR_MSB_0001' })
      }
    },
    closeCate () {
      this.fullCateVisible = false
    },
    // 23.12.05 랭킹리스트 변경 추가
    calcTranslateY (index) {
      const translateY = index * this.itemHeight
      return translateY
    },
    startRolling () {
      try {
        const slideTarget = this.$refs.rankingListArea
        const firstSilde = slideTarget.children[0].cloneNode(true)

        slideTarget.appendChild(firstSilde)

        this.trendInterval = setInterval(() => {
          if (this.currentIndex < this.rankingList.length) {
            this.currentIndex += 1
          } else if (this.currentIndex === this.rankingList.length) {
            slideTarget.classList.add('transition-none')
            this.currentIndex = 0
            setTimeout(() => {
              slideTarget.classList.remove('transition-none')
            })
          }
        }, 2000)
      } catch (e) {
        console.log('에러났음!')
        setTimeout(() => {
          this.startRolling()
        }, 500)
      }
    },
    resetFilterCheck () {
      sessionStorage.setItem('filterCheck', [])
      sessionStorage.setItem('prodSortCheck', '')
    }
  }
}
