

import { getCdnUrl, getResizedImgUrl } from '~/scriptData/common/interM2'

export default {
  name: 'CommonImage',
  props: {
    alt: {
      type: String,
      default: ''
    },
    src: {
      type: String,
      default: ''
    },
    imageClass: {
      type: String,
      default: ''
    },
    // resize는 '398x497' 와 같이 String 형식으로 들어올 수도 있음
    resize: {
      type: [String, Number],
      default: null
    },
    quality: {
      type: Number,
      default: null
    },
    format: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: null
    },
    isStaticImage: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      isErrorHandled: false,
      noImagePath: '/images/tempLy/noimage.png',
      currentSrc: null
    }
  },
  computed: {
    avifSrc () {
      return this.processedUrl(getCdnUrl(getResizedImgUrl(this.src, this.resize, this.quality, 'avif')))
    },
    webpSrc () {
      return this.processedUrl(getCdnUrl(getResizedImgUrl(this.src, this.resize, this.quality, 'webp')))
    },
    defaultSrc () {
      return this.processedUrl(getCdnUrl(getResizedImgUrl(this.src, this.resize, this.quality, '')))
    },
    staticImageSrc () {
      return this.processedUrl(this.src)
    },
    fallbackSrc () {
      return this.error || this.noImagePath
    },
  },
  watch: {
    // src가 변경될 때마다 currentSrc도 초기화
    src: {
      immediate: true,
      handler () {
        this.isErrorHandled = false
        this.currentSrc = this.isStaticImage ? this.staticImageSrc : this.defaultSrc
      }
    }
  },
  methods: {
    load () {
      this.$emit('load')
    },
    errorImage (e) {
      if (!this.isErrorHandled) {
        this.isErrorHandled = true
        this.currentSrc = this.fallbackSrc
      }
    },
    processedUrl (url) {
      if (!url) { return this.fallbackSrc }
      if (this.isErrorHandled || url.includes(this.noImagePath)) {
        return this.fallbackSrc
      }
      return url
    }
  }
}

