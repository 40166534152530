import { render, staticRenderFns } from "./SwiperCmp.vue?vue&type=template&id=e0bf5264&"
import script from "./SwiperCmp.vue?vue&type=script&lang=js&"
export * from "./SwiperCmp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonImage: require('/home/jenkins/.jenkins/workspace/prd-daiso-fo-web/daiso_vue_fo/components/common/CommonImage.vue').default})
